import React from 'react'

import Layout from '../components/layout'
import StrategyOne from '../components/strategy/StrategyOne'


const Work = props => (
  <Layout lang="en" title="Strategy ESG" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <StrategyOne lang="en" />
  </Layout>
)

export default Work
